import { localStorageKeys } from '../local-storage';
import {
  AccountState,
  OnboardingState,
  UserAccessLevelPermissionState,
  UserState
} from '../../views/components/user/state';
import { RootState } from '../../store/store';
import { useSelector } from 'react-redux';
import get from 'lodash/get';
import * as CryptoJS from 'crypto-js';
import { t } from 'i18next';
import { PlanLevel, SubscriptionActiveStatus } from './variable';

const ctyptoSecretKey = `${process.env.REACT_APP_SECRET_KEY}`;

export const useCurrentUserInfo = (): UserState | undefined => {
  const storedUserData = localStorage.getItem('userData');
  const userStoreInfo = useSelector((state: RootState) => get(state, 'user.user.userInfo'));

  return storedUserData ? storedUserData && decryptData(storedUserData)?.userInfo : userStoreInfo;
};

export const useCurrentOnboardingUserInfo = (): OnboardingState | undefined => {
  const storedOnboardingUserData = localStorage.getItem(t('onboarding.user'));
  // const userStoreInfo = useSelector((state: RootState) => get(state, 'user.user.userInfo'));

  return storedOnboardingUserData ? decryptData(storedOnboardingUserData)?.userInfo : null;
};

export const useAuthToken = (): string => {
  const storedOnboardingUserData = localStorage.getItem(t('onboarding.user'));
  const storedUserData = localStorage.getItem('userData');

  const userStoreInfo = useSelector((state: RootState) => get(state, 'user.user.token'));

  return storedOnboardingUserData
    ? decryptData(storedOnboardingUserData).token
    : storedUserData
      ? decryptData(storedUserData).token
      : userStoreInfo;
};

export const setSelectedAccountInfo = (accountId: number, userAccounts: AccountState[]) => {
  const selectedAccountInfo =
    userAccounts && userAccounts.find((userAccount: AccountState) => userAccount.id === accountId);

  localStorage.setItem(
    localStorageKeys.SELECTED_ACCOUNT,
    encryptData(JSON.stringify(selectedAccountInfo))
  );
};

export const getSelectedAccountInfo = () => {
  const selectedAccount = localStorage.getItem(localStorageKeys.SELECTED_ACCOUNT);

  return selectedAccount ? decryptData(selectedAccount) : null;
};

export const Logout = () => {
  localStorage.clear();
  sessionStorage.clear();
  return;
};

export const tokenCheck = (status: number | undefined) => {
  if (status === 401) {
    // Trigger a custom event for logout
    const event = new Event('logout');
    window.dispatchEvent(event);
  }
  return;
};

// Function to encrypt a message using the secret key
export const encryptData = (message: any, safeUrl?: boolean): any => {
  const encrypted = CryptoJS.AES.encrypt(message, ctyptoSecretKey).toString();
  if (safeUrl) {
    return base64ToUrlSafe(encrypted); // Convert to URL-safe format
  }
  return encrypted;
};

const base64ToUrlSafe = (base64: string): string => {
  return base64.replace(/\+/g, '-').replace(/\//g, '_').replace(/=+$/, ''); // Convert to URL-safe format
};

// Function to convert URL-safe Base64 to standard Base64
const urlSafeToBase64 = (urlSafe: string): string => {
  let base64 = urlSafe.replace(/-/g, '+').replace(/_/g, '/'); // Convert back to standard Base64
  const paddingNeeded = (4 - (base64.length % 4)) % 4; // Calculate padding
  if (paddingNeeded > 0) {
    base64 += '='.repeat(paddingNeeded); // Add padding
  }
  return base64;
};


// Function to decrypt an encrypted message using the secret key
export const decryptData = (encryptedMessage: any, withoutJson?: boolean): any => {
  try {
    if (withoutJson) {
      encryptedMessage = urlSafeToBase64(encryptedMessage); // Convert to standard Base64
    }
    const decrypted = CryptoJS.AES.decrypt(encryptedMessage, ctyptoSecretKey).toString(CryptoJS.enc.Utf8);
    if (withoutJson) {
      return decrypted;
    }
    return JSON.parse(decrypted); // Return the decrypted string
  } catch (error) {
    console.error("Decryption failed:", error);
    return null; // Return null on failure
  }
};


// Encrypt function to work at both side laravel and react
export const dataEncrypt = (data: string) => {
  let encryptedData = '';
  for (let i = 0; i < data.length; i++) {
    const charCode = data.charCodeAt(i);
    const offset = ctyptoSecretKey.charCodeAt(i % ctyptoSecretKey.length);
    encryptedData += String.fromCharCode(charCode + offset);
  }
  return base64ToUrlSafe(btoa(encryptedData)); // Convert binary data to base64
}

// Encrypt function to work at both side laravel and react
export const dataDecrypt = (encryptedData: string) => {
  let decryptedData = '';
  encryptedData = atob(urlSafeToBase64(encryptedData));
  for (let i = 0; i < encryptedData.length; i++) {
    const charCode = encryptedData.charCodeAt(i);
    const offset = ctyptoSecretKey.charCodeAt(i % ctyptoSecretKey.length);
    decryptedData += String.fromCharCode(charCode - offset);
  }
  return decryptedData;
}

// Function to check user access permission
export const checkUserPermission = (
  user: UserState | undefined,
  page: string,
  subPage: number = 0,
  permission: string = ''
) => {
  const userPermissions = user?.permissions;

  let permissions: UserAccessLevelPermissionState[] | undefined = [];
  if (page && subPage && permission) {
    permissions = userPermissions?.filter(
      (userPermission) =>
        userPermission.page === page &&
        userPermission.sub_page === subPage &&
        userPermission.permissions.includes(permission)
    );
  } else if (page && subPage) {
    permissions = userPermissions?.filter(
      (userPermission) =>
        userPermission.page === page &&
        userPermission.sub_page === subPage &&
        userPermission.permissions.length > 0
    );
  } else if (page) {
    permissions = userPermissions?.filter(
      (userPermission) => userPermission.page === page && userPermission.permissions.length > 0
    );
  }

  if (permissions && permissions.length > 0) {
    return true;
  }

  return false;
};

// Function to check user access permission
export const checkUserIsNewEntrant = (user: UserState | undefined) => {
  return user?.lu_user_access_level_id === 5;
};

export function isSubscriptionExpired(userAccounts: AccountState) {
  if(userAccounts && (PlanLevel.includes(userAccounts.plan_level!) || (userAccounts.current_subscription && SubscriptionActiveStatus.includes(userAccounts.current_subscription.status.toString())))){
    return false;
  }
  
  return true;
}
