import React, { useEffect } from 'react';

const LoginEmbed = () => {
  useEffect(() => {
    const domain =
      process.env.REACT_APP_ENV === 'production'
        ? '3lines.outseta.com'
        : '3lines---staging.outseta.com';

    // Configuration options for the Outseta login embed
    const o_login_options = {
      id: 'Outseta',
      domain: domain,
      load: 'auth',
      auth: {
        widgetMode: 'login',
        id: 'login_embed',
        mode: 'embed',
        selector: '#login-embed'
      }
    };

    // Convert the options into a string and attach them to the window
    (window as any).o_login_options = o_login_options;

    // Create the script tag dynamically
    const script = document.createElement('script');
    script.src = 'https://cdn.outseta.com/outseta.min.js';
    script.setAttribute('data-options', 'o_login_options');
    script.async = true;

    // Append the script to the body
    document.body.appendChild(script);

    // Cleanup function to remove the script when the component unmounts
    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <div>
      {/* This div is where the Outseta login form will be embedded */}
      <div id="login-embed"></div>
    </div>
  );
};

export default LoginEmbed;
